// src/components/HomePage.js
import React, { useState } from 'react';
import AdminHeader from './AdminHeader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'
import Form from '../Form'
import AdminSidebar from './AdminSidebar';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';



const TeacherProfileAdmin = ({ teacherData }) => {
    const navigate = useNavigate();
    const [collapse, setcollapse] = useState(false);
    const collapsesidebar = () => {
        setcollapse(!collapse);
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const schoolFormFields = [
        { label: 'Class Name', name: 'classname', validate: (val) => val === '', errorMessage: 'Class Name is required' },
        { label: 'Section Name', name: 'sectionname', validate: (val) => val === '', errorMessage: 'Section Name required' }
    ];


    const profileData = {
        imageUrl: 'https://via.placeholder.com/150',
        name: 'John Doe',
        email: 'john.doe@example.com',
        phone: '+1234567890',
        address: '123 Main St, Anytown, USA',
        gender: 'Male',
        dob: '1990-01-01',
        qualification: 'MSc Computer Science',
        specialization: 'Full Stack Development',
        workExperience: '10 Years',
        contractType: 'Full-Time',
    };
    const handleSchoolFormSubmit = (formData) => {
        console.log('Submitting school form', formData);
        // Make API call here
    };
    const goBack = () => {
        navigate('/admin/teachers')
    }
    return (
        <>
            <div className='headerpart'>
                <AdminHeader />
            </div>
            <div className='boxparent'>
                <AdminSidebar collapsesidebar={collapsesidebar} />
                <div className={collapse ? 'parentarea collapsed' : 'parentarea'}>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Make Class Teacher</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form fields={schoolFormFields} onSubmit={handleSchoolFormSubmit} buttonText="Add" />
                        </Modal.Body>

                    </Modal>
                    <Row>
                        <Col className='pagetitle'>
                            <span className='back' onClick={goBack}>Teachers /</span>
                            {teacherData !== null &&
                                <span className='blackcolor'> {teacherData.name}</span>
                            }
                        </Col>
                    </Row>
                    <div className='profileParent commonbgradius'>
                        <div className='tab-content'>
                            {teacherData !== null &&
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col md={4} className="text-center">
                                                <img
                                                    src={profileData.imageUrl}
                                                    alt="Profile"
                                                    className="img-fluid rounded-circle mb-3"
                                                />
                                                <h4>{profileData.name}</h4>
                                                <div className='contact changepwdbtn alignhorizontalcenter' onClick={handleShow}>Make ClassTeacher</div>

                                            </Col>
                                            <Col md={8}>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Email:</strong></Col>
                                                    <Col sm={8}>{profileData.email}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Phone:</strong></Col>
                                                    <Col sm={8}>{teacherData.name}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Address:</strong></Col>
                                                    <Col sm={8}>{profileData.address}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Gender:</strong></Col>
                                                    <Col sm={8}>{profileData.gender}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Date of Birth:</strong></Col>
                                                    <Col sm={8}>{profileData.dob}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Qualification:</strong></Col>
                                                    <Col sm={8}>{profileData.qualification}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Specialization:</strong></Col>
                                                    <Col sm={8}>{teacherData.subject}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Work Experience:</strong></Col>
                                                    <Col sm={8}>{teacherData.workExperience}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Contract Type:</strong></Col>
                                                    <Col sm={8}>{profileData.contractType}</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            }
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
};

export default TeacherProfileAdmin;