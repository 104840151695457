// src/components/HomePage.js
import React from 'react';
import {  NavLink } from 'react-router-dom'

import '../global.css'
const AdminHeader = () => {
    return (
        <>
            <div className="homepage-ver-11">
                <header className="frame-parent">
                    <div className="frame-wrapper">
                        <a href="/" className="group-parent">
                            <img alt="" className="group-icon1 logoimg" src={require('../images/logo.png')} />
                        </a>
                    </div>
                    <div className="frame-container">
                        <div className="about-parent">
                            <div className="updates"><NavLink exact="true" className={({isActive}) => (isActive ? "active profilepicboxparent" : 'profilepicboxparent')} to={'/Admin/Profile'}>Profile <div className='profilepicbox'></div></NavLink></div>
                        </div>
                    </div>
                </header>

            </div>
        </>
    );
};

export default AdminHeader;