// src/components/HomePage.js
import React, { useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './homepage.css'
import './global.css'
import { TypeAnimation } from 'react-type-animation';






const HomePage = () => {
    const settings = {
        responsive: [
            {
              breakpoint: 2000,
              settings: "unslick"
            },
            {
              breakpoint: 1600,
              settings: "unslick"
            },
            {
              breakpoint: 1024,
              settings: "unslick"
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                swipeToSlide: true,
                infinite: false,
                loop: false,
                variableWidth:true
              }
            }
          ]
    };

    useEffect(() => {



        const premises = document.querySelectorAll(".premise");
        const showPremisesSequentially = (index) => {
            if (index < premises.length) {
                setTimeout(() => {
                    premises[index].classList.add("animate");
                    showPremisesSequentially(index + 1);
                }, 300);
            }
        };

        showPremisesSequentially(0);
    }, []);

    const handleContactHover = () => {
        document.querySelector('.contactpopup').classList.toggle('halfshow');
    };

    const handleContactClick = (parameter) => (event) => {
        event.stopPropagation()
        document.querySelector('.contactpopup').classList.toggle('fullshow');
        event.target.classList.toggle('active');
    };
    const handlepropogation = (parameter) => (event) => {
        event.stopPropagation()
    };

    const handleMobileMenuClick = () => {
        document.body.classList.toggle('open');
    };
    useEffect(() => {
        const handleWindowClick = (e) => {
            e.stopImmediatePropagation()
            document.querySelector('.contactpopup').classList.remove('fullshow');
            document.querySelectorAll('.contact').forEach(el => el.classList.remove('active'));
        };

        window.addEventListener('click', handleWindowClick);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, []);

    return (
        <>
            <div className="homepage-ver-11">
                <header className="frame-parent">
                    <div className="frame-wrapper">
                        <a href="/" className="group-parent">
                            <img alt="" className="group-icon1 logoimg" src={require('./images/logo.png')} />
                        </a>
                    </div>
                    <div className="frame-container">
                        <div className="about-parent">
                            <div className="about"><a href="/#">About</a></div>
                            <div className="sell-to-us"><a href="/#">Solutions</a></div>
                            <div className="team"><a href="/#">Pricing</a></div>
                            <div className="updates"><a href="/#">Updates</a></div>
                            <div className="updates"><a href="/#">FAQ’s</a></div>
                        </div>
                        <div className="mobilehomeburger" onClick={handleMobileMenuClick}></div>
                    </div>
                    <button className="vector-parent">
                        <div className="contact" onMouseEnter={handleContactHover} onMouseLeave={handleContactHover} onClick={handleContactClick('abc')}>Login</div>
                    </button>
                </header>
                <section className="homepage-ver-11-inner">
                    <div className="building-on-legacy-for-a-bette-parent">
                        <h1 className="building-on-legacy" >
                            Edutech Mantra is a modern day <span>EdTech application</span>.


                        </h1>
                        <div className="were-an-investment-group-form-wrapper">
                            <div className="were-an-investment" >
                                Making life simpler for GenZ Students, Parents, Teachers, Principal & Admin.
                            </div>
                        </div>
                    </div>
                </section>
                <section className="homepage-ver-11-child">
                    {/* <div className="premise-parent"> */}
                    <Slider {...settings} className="premise-parent">
                        <div className="premise">
                            <div className="premise-wrapper">
                                <b className="premise1">Admin/Principal/Cordinator</b>
                            </div>
                            <div className="imgparent">
                                <img alt='' src={require('./images/signup_parents.png')} />
                            </div>
                        </div>
                        <div className="premise">
                            <div className="premise-wrapper">
                                <b className="premise1">Teachers</b>
                            </div>
                            <div className="imgparent">
                                <img alt='' src={require('./images/signup_counseling.png')} />
                            </div>
                        </div>
                        <div className="premise">
                            <div className="premise-wrapper">
                                <b className="premise1">Students</b>
                            </div>
                            <div className="imgparent">
                                <img alt='' src={require('./images/signup_students.png')} />
                            </div>
                        </div>
                        <div className="premise">
                            <div className="premise-wrapper">
                                <b className="premise1">Features</b>
                            </div>
                            <div className="rectangle-parent">
                                <h3 className="spring-street-capital" id="textanimation">
                                    <TypeAnimation
                                        sequence={[
                                            // Same substring at the start will only be typed out once, initially
                                            'A software as comprehensive as your curriculum',
                                            1000, // wait 1s before replacing "Mice" with "Hamsters"
                                            'Student Information & Fee Management',
                                            1000,
                                            'User Friendly',
                                            1000,
                                            'Progress & Result',
                                            1000
                                        ]}
                                        wrapper="span"
                                        speed={50}
                                        style={{ lineHeight: '30px', fontSize: 24, fontFamily: 'Aileronsemibold' }}
                                        repeat={Infinity}
                                    />
                                </h3>
                                <button className="vector-group">
                                    <a href="/#" className="more-updates">More updates</a>
                                </button>
                            </div>
                        </div>
                    </Slider>
                    {/* </div> */}
                </section>
                <div className="features_sec" id="features">
                    <h3 className="title">Exclusive features to enhance digital collaboration
                    </h3>
                    <div className="featurebox">
                        <div>
                            <p>Paperless Work</p>
                            <img alt='' src={require('./images/feature_paperless_work-01.svg').default} />
                        </div>
                        <div>
                            <p>Digital Happiness</p>
                            <img alt='' src={require('./images/feature_digital_happiness-01.svg').default} />
                        </div>
                        <div>
                            <p>One on One Chat</p>
                            <img alt='' src={require('./images/feature_one_on_one_chat-01.svg').default} />
                        </div>
                        <span></span>
                        <div>
                            <p>Safety For Your Child</p>
                            <img alt='' src={require('./images/feature_child_safety-01.svg').default} />
                        </div>
                        <div>
                            <p>Accessing App Remotely</p>
                            <img alt='' src={require('./images/feature_accessing_app_remotely-01.svg').default} />
                        </div>
                        <div>
                            <p>Only School Friends</p>
                            <img alt='' src={require('./images/feature_only_school_friends-01.svg').default} />
                        </div>
                        <span></span>
                        <div>
                            <p>Great Parenting Zone</p>
                            <img alt='' src={require('./images/feature_great_parenting-01.svg').default} />
                        </div>
                        <div>
                            <p>Exclusive Teachers Profile</p>
                            <img alt='' src={require('./images/feature_exclusive_teacher_profile-01.svg').default} />
                        </div>
                        <span></span>
                        <div>
                            <p>Right Notifications</p>
                            <img alt='' src={require('./images/feature_right_notifications-01.svg').default} />
                        </div>
                        <div>
                            <p>Classroom Activity</p>
                            <img alt='' src={require('./images/feature_classroom_activity-01.svg').default} />
                        </div>
                        <div>
                            <p>Attendance Visibility</p>
                            <img alt='' src={require('./images/feature_attendance_visibility-01.svg').default} />
                        </div>
                        <span></span>
                        <div>
                            <p>Fast Technical Support</p>
                            <img alt='' src={require('./images/feature_help_tech_support-01.svg').default} />
                        </div>
                    </div>
                </div>

            </div >
            <footer>
                <p>&copy; Copyright 2024 All rights reserved.</p>
                <div className="privacybox">
                    <a href="/#">Privacy Policy</a>
                    <a href="/#">Terms of Use</a>
                </div>
            </footer>


            <div className="contactpopup contactweb" onClick={handlepropogation('abc')}>
                <div className="firstrow">
                    <p>Please enter your credentials below to login your account</p>
                </div>
                <div className='contactboxparent'>
                    <div>
                        <div className="emailboxparent">
                            <input type="email" placeholder="Enter email address" />
                            <input type="password" placeholder="Enter Password" />
                            <a href="/#">Forgot Password ?</a>
                            <input type="submit" value="Submit" />
                        </div>
                    </div>
                    <div className='imagebox'>
                        <img alt='' src={require('./images/signup_schools_universities_colleges.png')} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomePage;