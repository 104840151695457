// src/components/HomePage.js
import React, { useState } from 'react';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from '../Form'

const AdminProfile = () => {
    const [collapse, setcollapse] = useState(false);
    const collapsesidebar = () => {
        setcollapse(!collapse);
    }
    const emailValidation = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !re.test(String(email).toLowerCase());
    };

    const phoneValidation = (phone) => {
        const re = /^\d{10}$/;
        return !re.test(phone);
    };
    const schoolFormFields = [
        { label: 'Name', name: 'name', validate: (val) => val === '', errorMessage: 'Name is required' },
        { label: 'Email', name: 'email', validate: emailValidation, errorMessage: 'Invalid email' },
        { label: 'Phone', name: 'phone', validate: phoneValidation, errorMessage: 'Invalid phone number' },
        { label: 'School Name', name: 'schoolName', validate: (val) => val === '', errorMessage: 'School name is required' },
        { label: 'School Address', name: 'schoolAddress', validate: (val) => val === '', errorMessage: 'School address is required' },
        { label: 'Principal Name', name: 'principalName', validate: (val) => val === '', errorMessage: 'Principal name is required' },
        { label: 'Principal Contact', name: 'principalContact', validate: phoneValidation, errorMessage: 'Invalid contact number' },
        { label: 'Branch Code', name: 'branchCode', validate: (val) => val === '', errorMessage: 'Branch code is required' },
    ];
    const handleSchoolFormSubmit = (formData) => {
        console.log('Submitting school form', formData);
        // Make API call here
    };


    return (
        <>
            <div className='headerpart'>
                <AdminHeader />
            </div>
            <div className='boxparent'>
                <AdminSidebar collapsesidebar={collapsesidebar} />
                <div className={collapse ? 'parentarea collapsed' : 'parentarea'}>

                    <Row>
                        <Col className='pagetitle'>Account Information</Col>
                    </Row>
                    <div className='profileParent commonbgradius'>
                        <Form fields={schoolFormFields} onSubmit={handleSchoolFormSubmit} buttonText="Save" />
                        <div className='linebreak'></div>
                        <div className='contact changepwdbtn'>Change Password</div>
                    </div>

                </div>

            </div>
        </>
    );
};

export default AdminProfile;