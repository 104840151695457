// src/components/HomePage.js
import React, { useState } from 'react';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';

const AdminParents = () => {
    const [collapse, setcollapse] = useState(false);
    const collapsesidebar = () => {
        setcollapse(!collapse);
    }
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;

    const data = [
        { id: 1, name: 'John Doe', email: 'john@example.com', contact: '1234567890', address: '123 Main St', specialization: 'Math', feestatus:'due' },
        { id: 2, name: 'Jane Doe', email: 'jane@example.com', contact: '0987654321', address: '456 Elm St', specialization: 'Science', feestatus:'paid' },
        { id: 3, name: 'Sam Smith', email: 'sam@example.com', contact: '1122334455', address: '789 Oak St', specialization: 'English', feestatus:'paid' },
        { id: 4, name: 'Sara Johnson', email: 'sara@example.com', contact: '2233445566', address: '101 Pine St', specialization: 'History', feestatus:'paid' },
        { id: 5, name: 'Mike Lee', email: 'mike@example.com', contact: '3344556677', address: '202 Maple St', specialization: 'Art', feestatus:'due' },
        { id: 6, name: 'Chris Evans', email: 'chris@example.com', contact: '4455667788', address: '303 Birch St', specialization: 'Music', feestatus:'due' },
        { id: 7, name: 'Emma Watson', email: 'emma@example.com', contact: '5566778899', address: '404 Cedar St', specialization: 'Drama', feestatus:'paid' },
        { id: 8, name: 'Daniel Radcliffe', email: 'daniel@example.com', contact: '6677889900', address: '505 Spruce St', specialization: 'Physics', feestatus:'due' },
        { id: 9, name: 'Robert Downey', email: 'robert@example.com', contact: '7788990011', address: '606 Willow St', specialization: 'Chemistry', feestatus:'due' },
        { id: 10, name: 'Scarlett Johansson', email: 'scarlett@example.com', contact: '8899001122', address: '707 Aspen St', specialization: 'Biology', feestatus:'due' },
    ];

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    const offset = currentPage * itemsPerPage;
    const currentItems = data.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(data.length / itemsPerPage);
    return (
        <>
            <div className='headerpart'>
                <AdminHeader />
            </div>
            <div className='boxparent'>
                <AdminSidebar collapsesidebar={collapsesidebar} />
                <div className={collapse ? 'parentarea collapsed' : 'parentarea'}>

                    <Row>
                        <Col>Admin Parents/Students Page</Col>
                    </Row>
                    <div className='profileParent commonbgradius'>
                        <div className='tableparent'>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Address</th>
                                        <th>Specialization</th>
                                        <th>Fee Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{offset + index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.contact}</td>
                                            <td>{item.address}</td>
                                            <td>{item.specialization}</td>
                                            <td>{item.feestatus}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {data.length > itemsPerPage && (
                                <ReactPaginate
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default AdminParents;