// src/components/HomePage.js
import React, { useState } from 'react';
import TeacherHeader from './TeacherHeader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'
import Form from '../Form'
import TeacherSidebar from './TeacherSidebar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const TeacherProfile = () => {
    const [collapse, setcollapse] = useState(false);
    const collapsesidebar = () => {
        setcollapse(!collapse);
    }
    const emailValidation = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !re.test(String(email).toLowerCase());
    };

    const phoneValidation = (phone) => {
        const re = /^\d{10}$/;
        return !re.test(phone);
    };
    const schoolFormFields = [
        { label: 'Name', name: 'name', validate: (val) => val === '', errorMessage: 'Name is required' },
        { label: 'Email', name: 'email', validate: emailValidation, errorMessage: 'Invalid email' },
        { label: 'Phone', name: 'phone', validate: phoneValidation, errorMessage: 'Invalid phone number' },
        { label: 'Address', name: 'Address', validate: (val) => val === '', errorMessage: 'Address is required' },
        { label: 'Gender', name: 'Gender', validate: (val) => val === '', errorMessage: 'Gender is required' },
        { label: 'Date of Birth', name: 'dob', validate: (val) => val === '', errorMessage: 'Date of Birth is required' },
        { label: 'Qualification', name: 'Qualification', validate: (val) => val === '', errorMessage: 'Qualification is required' },
        { label: 'Specilization', name: 'Specilization', validate: (val) => val === '', errorMessage: 'Specilization is required' },
        { label: 'Work Experience', name: 'WorkExperience', validate: (val) => val === '', errorMessage: 'Work Experience is required' },
        { label: 'Contract Type', name: 'ContractType', validate: (val) => val === '', errorMessage: 'Contract Type is required' },

    ];
    const handleSchoolFormSubmit = (formData) => {
        console.log('Submitting school form', formData);
        // Make API call here
    };

    const profileData = {
        imageUrl: 'https://via.placeholder.com/150',
        name: 'John Doe',
        email: 'john.doe@example.com',
        phone: '+1234567890',
        address: '123 Main St, Anytown, USA',
        gender: 'Male',
        dob: '1990-01-01',
        qualification: 'MSc Computer Science',
        specialization: 'Full Stack Development',
        workExperience: '10 Years',
        contractType: 'Full-Time',
    };
    return (
        <>
            <div className='headerpart'>
                <TeacherHeader />
            </div>
            <div className='boxparent'>
                <TeacherSidebar collapsesidebar={collapsesidebar} />
                <div className={collapse ? 'parentarea collapsed' : 'parentarea'}>

                    <Row>
                        <Col className='pagetitle'>Account Information</Col>
                    </Row>
                    <div className='profileParent commonbgradius'>
                        <Tabs
                            defaultActiveKey="view"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="view" title="View profile">
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col md={4} className="text-center">
                                                <img
                                                    src={profileData.imageUrl}
                                                    alt="Profile"
                                                    className="img-fluid rounded-circle mb-3"
                                                />
                                                <h4>{profileData.name}</h4>
                                                <div className='contact changepwdbtn alignhorizontalcenter'>Update Profile Pic</div>

                                            </Col>
                                            <Col md={8}>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Email:</strong></Col>
                                                    <Col sm={8}>{profileData.email}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Phone:</strong></Col>
                                                    <Col sm={8}>{profileData.phone}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Address:</strong></Col>
                                                    <Col sm={8}>{profileData.address}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Gender:</strong></Col>
                                                    <Col sm={8}>{profileData.gender}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Date of Birth:</strong></Col>
                                                    <Col sm={8}>{profileData.dob}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Qualification:</strong></Col>
                                                    <Col sm={8}>{profileData.qualification}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Specialization:</strong></Col>
                                                    <Col sm={8}>{profileData.specialization}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Work Experience:</strong></Col>
                                                    <Col sm={8}>{profileData.workExperience}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Contract Type:</strong></Col>
                                                    <Col sm={8}>{profileData.contractType}</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Tab>
                            <Tab eventKey="edit" title="Edit profile">
                                <div className='editteacherprofileparent'>
                                    <Form fields={schoolFormFields} onSubmit={handleSchoolFormSubmit} buttonText="Save" />
                                    <div className='linebreak'></div>
                                    <div className='contact changepwdbtn'>Change Password</div>
                                    <div className='contact changepwdbtn'>Apply for Leave</div>

                                </div>
                            </Tab>
                        </Tabs>


                    </div>

                </div>

            </div>
        </>
    );
};

export default TeacherProfile;