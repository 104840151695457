// src/components/HomePage.js
import React, { useState } from 'react';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AdminNotifications = () => {
    const [collapse, setcollapse] = useState(false);
    const collapsesidebar = () => {
        setcollapse(!collapse);
    }
    return (
        <>
            <div className='headerpart'>
                <AdminHeader />
            </div>
            <div className='boxparent'>
                <AdminSidebar collapsesidebar={collapsesidebar} />
                <div className={collapse ? 'parentarea collapsed' : 'parentarea'}>
                    
                    <Row>
                        <Col>Admin Notifications Page</Col>
                    </Row>

                </div>

            </div>
        </>
    );
};

export default AdminNotifications;