// src/components/HomePage.js
import React, { useState } from 'react';
import StudentHeader from './StudentHeader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'
import Form from '../Form'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import StudentSidebar from './StudentSidebar';


const StudentProfile = () => {
    const [collapse, setcollapse] = useState(false);
    const collapsesidebar = () => {
        setcollapse(!collapse);
    }
    const emailValidation = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !re.test(String(email).toLowerCase());
    };

    const phoneValidation = (phone) => {
        const re = /^\d{10}$/;
        return !re.test(phone);
    };
    const schoolFormFields = [
        { label: 'Name', name: 'name', validate: (val) => val === '', errorMessage: 'Name is required' },
        { label: 'Email', name: 'email', validate: emailValidation, errorMessage: 'Invalid email' },
        { label: 'Phone', name: 'phone', validate: phoneValidation, errorMessage: 'Invalid phone number' },
        { label: 'Address', name: 'Address', validate: (val) => val === '', errorMessage: 'Address is required' },
        { label: 'Gender', name: 'Gender', validate: (val) => val === '', errorMessage: 'Gender is required' },
        { label: 'Date of Birth', name: 'dob', validate: (val) => val === '', errorMessage: 'Date of Birth is required' },
        { label: 'Class', name: 'std', validate: (val) => val === '', errorMessage: 'Class is required' },
        { label: 'Section', name: 'div', validate: (val) => val === '', errorMessage: 'Section is required' },
        { label: 'Father Name', name: 'fathername', validate: (val) => val === '', errorMessage: 'Father Name is required' },
        { label: 'Mother Name', name: 'mohername', validate: (val) => val === '', errorMessage: 'Mother Name  is required' },
        { label: 'Father Occupation', name: 'FOccupation', validate: (val) => val === '', errorMessage: 'Father Occupation  is required' },
        { label: 'Mother Occupation', name: 'MOccupation', validate: (val) => val === '', errorMessage: 'Mother Occupation  is required' },
        { label: 'Emergency Contact', name: 'emergencycontact', validate: phoneValidation, errorMessage: 'Emergency Contact number' },
        { label: 'Blood Group', name: 'bloodgroup', validate: (val) => val === '', errorMessage: 'Blood group required'},
        { label: 'Pickup Point', name: 'pickuppoint', validate: (value) => false,},
        { label: 'Bus root', name: 'busroot', validate: (value) => false},


    ];
    const handleSchoolFormSubmit = (formData) => {
        console.log('Submitting school form', formData);
        // Make API call here
    };

    const profileData = {
        imageUrl: 'https://via.placeholder.com/150',
        name: 'John Doe',
        email: 'john.doe@example.com',
        phone: '+1234567890',
        address: '123 Main St, Anytown, USA',
        gender: 'Male',
        dob: '1990-01-01',
        Class: '1St',
        Section: 'A',
        FatherName: 'Elon Musk',
        MotherNmae: 'Savitri bai',
        Emergency: '8908574789',
        bloodgroup: 'B+',
        PickupPoint:'Sector 12 bus stand',
        busroot:'mata mandir road'
    };
    return (
        <>
            <div className='headerpart'>
                <StudentHeader />
            </div>
            <div className='boxparent'>
                <StudentSidebar collapsesidebar={collapsesidebar} />
                <div className={collapse ? 'parentarea collapsed' : 'parentarea'}>

                    <Row>
                        <Col className='pagetitle'>Account Information</Col>
                    </Row>
                    <div className='profileParent commonbgradius'>
                        <Tabs
                            defaultActiveKey="view"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="view" title="View profile">
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col md={4} className="text-center">
                                                <img
                                                    src={profileData.imageUrl}
                                                    alt="Profile"
                                                    className="img-fluid rounded-circle mb-3"
                                                />
                                                <h4>{profileData.name}</h4>
                                                <div className='contact changepwdbtn alignhorizontalcenter'>Update Profile Pic</div>

                                            </Col>
                                            <Col md={8}>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Email:</strong></Col>
                                                    <Col sm={8}>{profileData.email}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Phone:</strong></Col>
                                                    <Col sm={8}>{profileData.phone}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Address:</strong></Col>
                                                    <Col sm={8}>{profileData.address}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Gender:</strong></Col>
                                                    <Col sm={8}>{profileData.gender}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Date of Birth:</strong></Col>
                                                    <Col sm={8}>{profileData.dob}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Class:</strong></Col>
                                                    <Col sm={8}>{profileData.Class}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Section:</strong></Col>
                                                    <Col sm={8}>{profileData.Section}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Father Name:</strong></Col>
                                                    <Col sm={8}>{profileData.FatherName}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Mother Name:</strong></Col>
                                                    <Col sm={8}>{profileData.MotherNmae}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Emergency Contact:</strong></Col>
                                                    <Col sm={8}>{profileData.Emergency}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Blood Group:</strong></Col>
                                                    <Col sm={8}>{profileData.bloodgroup}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Pickup Point:</strong></Col>
                                                    <Col sm={8}>{profileData.PickupPoint}</Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={4}><strong>Bus Root:</strong></Col>
                                                    <Col sm={8}>{profileData.busroot}</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Tab>
                            <Tab eventKey="edit" title="Update profile">
                                <div className='editteacherprofileparent'>
                                    <Form fields={schoolFormFields} onSubmit={handleSchoolFormSubmit} buttonText="Save" />
                                    <div className='linebreak'></div>
                                    <div className='contact changepwdbtn'>Change Password</div>
                                    <div className='contact changepwdbtn'>Apply for Leave</div>

                                </div>
                            </Tab>
                            <Tab eventKey="switch" title="Switch profile">
                                <div className='Switchprofiletabparent'>
                                    Switch profile
                                </div>
                            </Tab>
                        </Tabs>


                    </div>

                </div>

            </div>
        </>
    );
};

export default StudentProfile;