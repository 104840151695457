import React, { useState, useEffect } from 'react';
import './form.css';

const FloatingLabelInput = ({ label, value, onChange, validate, errorMessage, onBlur, type = 'text' }) => {
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        if (value !== '') {
            setTouched(true);
        }
    }, [value]);

    return (
        <div className="floating-label-input">
            <input
                type={type}
                value={value}
                onChange={onChange}
                onBlur={() => {
                    setTouched(true);
                    onBlur();
                }}
                placeholder=" " // Add placeholder for triggering animation
            />
            <label className={value ? 'filled' : ''}>{label}</label>
            {touched && validate(value) && <span className="error">{errorMessage}</span>}
        </div>
    );
};

const Form = ({ fields, onSubmit, buttonText }) => {
    const initialFormState = fields.reduce((acc, field) => {
        acc[field.name] = '';
        return acc;
    }, {});

    const [form, setForm] = useState(initialFormState);
    const [errors, setErrors] = useState({});

    const handleChange = (field) => (e) => {
        setForm({
            ...form,
            [field]: e.target.value,
        });

        // Clear errors for the field being updated
        if (errors[field]) {
            setErrors({
                ...errors,
                [field]: false,
            });
        }
    };

    const handleBlur = (field, validateFunc) => () => {
        // Validate on blur
        if (validateFunc) {
            setErrors({
                ...errors,
                [field]: validateFunc(form[field], form),
            });
        }
    };

    const handleSubmit = () => {
        const validationErrors = {};
        fields.forEach(({ name, validate }) => {
            if (validate && validate(form[name], form)) {
                validationErrors[name] = true;
            }
        });

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            onSubmit(form);
        }
    };

    const isFormValid = () => {
        return fields.every(({ name, validate }) => {
            return validate ? !validate(form[name], form) : true;
        });
    };

    return (
        <>
            {fields.map(({ label, name, validate, errorMessage, type }) => (
                <FloatingLabelInput
                    key={name}
                    label={label}
                    value={form[name]}
                    onChange={handleChange(name)}
                    validate={(value) => validate(value, form)} // Pass the entire form state
                    errorMessage={errorMessage}
                    type={type}
                    onBlur={handleBlur(name, validate)}
                />
            ))}
            <div className='linebreak'></div>
            <div>
                <button className='contact' onClick={handleSubmit} disabled={!isFormValid()}>{buttonText}</button>
            </div>
        </>
    );
};

export default Form;
