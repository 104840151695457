// src/components/HomePage.js
import React, { useState } from 'react';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { NavLink } from 'react-router-dom';
import { MdSchedule } from "react-icons/md";
import { MdEmojiTransportation } from "react-icons/md";
import { IoLibrary } from "react-icons/io5";
import { FaBell } from "react-icons/fa";
import { PiExam } from "react-icons/pi";
import { FaBookOpen } from "react-icons/fa";
import { MdMeetingRoom } from "react-icons/md";




const AdminDashboard = () => {
    const [collapse, setcollapse] = useState(false);
    const collapsesidebar = () => {
        setcollapse(!collapse);
    }
    return (
        <>

            <div className='headerpart'>
                <AdminHeader />
            </div>
            <div className='boxparent'>
                <AdminSidebar collapsesidebar={collapsesidebar} />
                <div className={collapse ? 'parentarea collapsed' : 'parentarea'}>
                    
                    <Row>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><MdSchedule /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Class TimeTable</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><MdSchedule /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Teachers TimeTable</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><MdEmojiTransportation /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Transport</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><IoLibrary /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Library</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><FaBell /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Announcement</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><PiExam /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Examinations</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><FaBookOpen /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Cources</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><MdMeetingRoom /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Meetings</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="topprograssstart">
                                <p className="text-uppercase mt5 clearfix">Fees Awaiting Payment<span className="pull-right">9/46</span>
                                </p>
                                <div className="progress-group">
                                    <ProgressBar variant="success" now={20} />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="topprograssstart">
                                <p className="text-uppercase mt5 clearfix">Staff Present Today<span className="pull-right">11/20</span>
                                </p>
                                <div className="progress-group">
                                    <ProgressBar variant="success" now={55} />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="topprograssstart">
                                <p className="text-uppercase mt5 clearfix">Student Present Today<span className="pull-right">180/200</span>
                                </p>
                                <div className="progress-group">
                                    <ProgressBar variant="success" now={80} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="topprograssstart">
                                <h5 className="pro-border pb10">Fees Overview</h5>
                                <p className="text-uppercase mt10 clearfix">29 Unpaid<span className="pull-right">64.44%</span>
                                </p>
                                <div className="progress-group">
                                    <ProgressBar variant="success" now={64.44} />
                                </div>
                                <p className="text-uppercase mt10 clearfix">7 Partial<span className="pull-right">15.56%</span>
                                </p>
                                <div className="progress-group">
                                    <ProgressBar variant="success" now={15.56} />
                                </div>
                                <p className="text-uppercase mt10 clearfix">9 Paid<span className="pull-right">20%</span>
                                </p>
                                <div className="progress-group">
                                    <ProgressBar variant="success" now={20} />
                                </div>
                            </div>
                        </Col>

                        <Col>
                            <div className="box box-primary borderwhite">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Teachers List</h3>
                                    <NavLink exact="true" className={({ isActive }) => (isActive ? "active" : '')} to={'/Admin/Profile'}>View All</NavLink>
                                </div>

                                <div className="box-body direct-chat-messages">
                                    <ul className="user-progress ps">
                                        <li className="lecture-list">

                                            <img src="https://demo.smart-school.in/uploads/staff_images/1704266952-23057833365950cc83230d!2.jpg?1716749534" alt="" className="img-circle msr-3 object-fit-cover fit-image-40" width="40" height="40" />

                                            <div className="set-flex">
                                                <div className="media-title bmedium">Shivam Verma<br /> (9002) <span className="label label-success bolds">Class Teacher</span>
                                                </div>

                                            </div>
                                        </li>

                                        <li className="lecture-list">

                                            <img src="https://demo.smart-school.in/uploads/staff_images/1694000194-74033790764f86442da1de!Alastair-.jpg?1716749534" alt="" className="img-circle msr-3 object-fit-cover fit-image-40" width="40" height="40" />

                                            <div className="set-flex">
                                                <div className="media-title bmedium">Jason Sharlton<br /> (900002301)
                                                </div>

                                            </div>
                                        </li>
                                        <li className="lecture-list">

                                            <img src="https://demo.smart-school.in/uploads/staff_images/1694000194-74033790764f86442da1de!Alastair-.jpg?1716749534" alt="" className="img-circle msr-3 object-fit-cover fit-image-40" width="40" height="40" />

                                            <div className="set-flex">
                                                <div className="media-title bmedium">Jason Sharlton<br /> (900002301)
                                                </div>

                                            </div>
                                        </li>
                                        <li className="lecture-list">

                                            <img src="https://demo.smart-school.in/uploads/staff_images/1704266952-23057833365950cc83230d!2.jpg?1716749534" alt="" className="img-circle msr-3 object-fit-cover fit-image-40" width="40" height="40" />

                                            <div className="set-flex">
                                                <div className="media-title bmedium">Shivam Verma<br /> (9002) <span className="label label-success bolds">Class Teacher</span>
                                                </div>

                                            </div>
                                        </li>

                                        <li className="lecture-list">

                                            <img src="https://demo.smart-school.in/uploads/staff_images/1694000194-74033790764f86442da1de!Alastair-.jpg?1716749534" alt="" className="img-circle msr-3 object-fit-cover fit-image-40" width="40" height="40" />

                                            <div className="set-flex">
                                                <div className="media-title bmedium">Jason Sharlton<br /> (900002301)
                                                </div>

                                            </div>
                                        </li>
                                        <li className="lecture-list">

                                            <img src="https://demo.smart-school.in/uploads/staff_images/1694000194-74033790764f86442da1de!Alastair-.jpg?1716749534" alt="" className="img-circle msr-3 object-fit-cover fit-image-40" width="40" height="40" />

                                            <div className="set-flex">
                                                <div className="media-title bmedium">Jason Sharlton<br /> (900002301)
                                                </div>

                                            </div>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col>
                            <div className="box box-primary borderwhite">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Students List</h3>
                                    <NavLink exact="true" className={({ isActive }) => (isActive ? "active" : '')} to={'/Admin/Profile'}>View All</NavLink>
                                </div>

                                <div className="box-body direct-chat-messages">
                                    <ul className="user-progress ps">
                                        <li className="lecture-list">

                                            <img src="https://demo.smart-school.in/uploads/staff_images/1704266952-23057833365950cc83230d!2.jpg?1716749534" alt="" className="img-circle msr-3 object-fit-cover fit-image-40" width="40" height="40" />

                                            <div className="set-flex">
                                                <div className="media-title bmedium">Sonu Yadav<br /> <div>5<sup>th</sup> std. Section A</div>
                                                </div>

                                            </div>
                                        </li>

                                        <li className="lecture-list">

                                            <img src="https://demo.smart-school.in/uploads/staff_images/1694000194-74033790764f86442da1de!Alastair-.jpg?1716749534" alt="" className="img-circle msr-3 object-fit-cover fit-image-40" width="40" height="40" />

                                            <div className="set-flex">
                                                <div className="media-title bmedium">Babbu Singh<br /> <div>Nursery std. Section A</div>
                                                </div>

                                            </div>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </div>

            </div>
        </>
    );
};

export default AdminDashboard;