// src/components/HomePage.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import { FiMonitor } from "react-icons/fi";
import { FaUserSecret } from "react-icons/fa";
import { HiUsers } from "react-icons/hi2";
import { FaBell } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import { FaBarsStaggered } from "react-icons/fa6";
import '../global.css'
const AdminHeader = ({collapsesidebar}) => {
    const [collapse, setcollapse] = useState(false);
    const docollapse = () => {
        setcollapse(!collapse);
        collapsesidebar()
    }
    return (
        <>
            <div className={collapse ? 'sidebar collapsed' : 'sidebar'}>
                <div className="frame-container">
                    <div className='menubarbar' onClick={() => docollapse()}><FaBarsStaggered /></div>
                    <div className="about-parent">
                        <div className="about"><NavLink exact="true" className={({ isActive }) => (isActive ? "active" : 'none')} to={'/Admin/Dashboard'}><FiMonitor />
                            Dashboard</NavLink></div>
                        <div className="about"><NavLink exact="true" className={({ isActive }) => (isActive ? "active" : 'none')} to={'/Admin/Teachers'}><FaUserSecret />
                            Teachers</NavLink></div>
                        <div className="sell-to-us"><NavLink exact="true" className={({ isActive }) => (isActive ? "active" : 'none')} to={'/Admin/Parents'}><HiUsers />Parents/Students</NavLink></div>
                        <div className="team"><NavLink exact="true" className={({ isActive }) => (isActive ? "active" : 'none')} to={'/Admin/Notifications'}><FaBell />Notifications</NavLink></div>
                        <div className="team"><NavLink  to={'/'}><IoLogOut />Logout</NavLink></div>
                    </div>
                    {/* <div className="mobilehomeburger" onClick={handleMobileMenuClick}></div> */}
                </div>
            </div>
        </>
    );
};

export default AdminHeader;