// src/components/HomePage.js
import React, { useState } from 'react';
import StudentHeader from './StudentHeader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { NavLink } from 'react-router-dom';
import { MdSchedule } from "react-icons/md";
import { FaBell } from "react-icons/fa";
import { PiExam } from "react-icons/pi";
import { FaBookOpen } from "react-icons/fa";
import { FaCalendar } from "react-icons/fa";
import { FaHourglassEnd } from "react-icons/fa";
import { PiStudentBold } from "react-icons/pi";
import StudentSidebar from './StudentSidebar';
import { CiCreditCard1 } from "react-icons/ci";
import { FaPersonCircleQuestion } from "react-icons/fa6";
import { IoShareSocialSharp } from "react-icons/io5";
import { AiFillCode } from "react-icons/ai";



const StudentDashboard = () => {
    const [collapse, setcollapse] = useState(false);
    const collapsesidebar = () => {
        setcollapse(!collapse);
    }
    return (
        <>

            <div className='headerpart'>
                <StudentHeader />
            </div>
            <div className='boxparent'>
                <StudentSidebar collapsesidebar={collapsesidebar} />
                <div className={collapse ? 'parentarea collapsed' : 'parentarea'}>

                    <Row>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><MdSchedule /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Class TimeTable</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><FaBell /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Announcement</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><PiExam /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Examinations</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><FaCalendar /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Attendance</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><FaHourglassEnd /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Homework</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><CiCreditCard1 /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Fees</span>
                                    </div>
                                </a>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><FaPersonCircleQuestion /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Doubt</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><IoShareSocialSharp /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Social</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <div className="info-box">
                                <a href="/#">
                                    <span className="info-box-icon bg-yellow"><AiFillCode /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Ai Doubt clear</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                    </Row>

                </div>

            </div>
        </>
    );
};

export default StudentDashboard;