import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import AdminProfile from './Admin/AdminProfile';
import AdminDashboard from './Admin/AdminDashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './global.css'
import AdminTeachers from './Admin/AdminTeachers';
import AdminParents from './Admin/AdminParents';
import AdminNotifications from './Admin/AdminNotifications';
import TeacherProfile from './Teachers/TeacherProfile';
import TeacherDashboard from './Teachers/TeacherDashboard';
import TeacherStudent from './Teachers/TeacherStudent';
import TeacherExamination from './Teachers/TeacherExamination';
import ChangePassword from './ChangePassword';
import StudentDashboard from './Students/StudentDashboard';
import StudentProfile from './Students/StudentProfile';
import TeacherProfileAdmin from './Admin/TeacherProfileAdmin';



function App() {
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const handleTeacherSelect = (teacherData) => {
    setSelectedTeacher(teacherData);
  };
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Admin/Profile" element={<AdminProfile />} />
        <Route path="/Admin/Dashboard" element={<AdminDashboard />} />
        <Route path="/Admin/Teachers" element={<AdminTeachers onTeacherSelect={handleTeacherSelect}/>} />
        <Route path="/Admin/Teachers/:name" element={<TeacherProfileAdmin teacherData={selectedTeacher}/>} />
        <Route path="/Admin/Parents" element={<AdminParents />} />
        <Route path="/Admin/Notifications" element={<AdminNotifications />} />
        <Route path="/Teacher/Profile" element={<TeacherProfile />} />
        <Route path="/Teacher/Dashboard" element={<TeacherDashboard />} />
        <Route path="/Teacher/Students" element={<TeacherStudent />} />
        <Route path="/Teacher/Examination" element={<TeacherExamination />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/Student/Dashboard" element={<StudentDashboard />} />
        <Route path="/Student/Profile" element={<StudentProfile />} />



        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
