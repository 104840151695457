// src/components/HomePage.js
import React, { useState } from 'react';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from '../Form'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';





const AdminTeachers = ({ onTeacherSelect }) => {
    const navigate = useNavigate();
    const [collapse, setcollapse] = useState(false);
    const collapsesidebar = () => {
        setcollapse(!collapse);
    }
    const emailValidation = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return !re.test(String(email).toLowerCase());
    };

    const phoneValidation = (phone) => {
        const re = /^\d{10}$/;
        return !re.test(phone);
    };

    const schoolFormFields = [
        { label: 'Name', name: 'name', validate: (val) => val === '', errorMessage: 'Name is required' },
        { label: 'Email', name: 'email', validate: emailValidation, errorMessage: 'Invalid email' },
        { label: 'Phone', name: 'phone', validate: phoneValidation, errorMessage: 'Invalid phone number' }
    ];
    const handleSchoolFormSubmit = (formData) => {
        console.log('Submitting school form', formData);
        // Make API call here
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;

    const data = [
        { id: 1, name: 'John Doe', email: 'john@example.com', contact: '1234567890', address: '123 Main St', specialization: 'Math' },
        { id: 2, name: 'Jane Doe', email: 'jane@example.com', contact: '0987654321', address: '456 Elm St', specialization: 'Science' },
        { id: 3, name: 'Sam Smith', email: 'sam@example.com', contact: '1122334455', address: '789 Oak St', specialization: 'English' },
        { id: 4, name: 'Sara Johnson', email: 'sara@example.com', contact: '2233445566', address: '101 Pine St', specialization: 'History' },
        { id: 5, name: 'Mike Lee', email: 'mike@example.com', contact: '3344556677', address: '202 Maple St', specialization: 'Art' },
        { id: 6, name: 'Chris Evans', email: 'chris@example.com', contact: '4455667788', address: '303 Birch St', specialization: 'Music' },
        { id: 7, name: 'Emma Watson', email: 'emma@example.com', contact: '5566778899', address: '404 Cedar St', specialization: 'Drama' },
        { id: 8, name: 'Daniel Radcliffe', email: 'daniel@example.com', contact: '6677889900', address: '505 Spruce St', specialization: 'Physics' },
        { id: 9, name: 'Robert Downey', email: 'robert@example.com', contact: '7788990011', address: '606 Willow St', specialization: 'Chemistry' },
        { id: 10, name: 'Scarlett Johansson', email: 'scarlett@example.com', contact: '8899001122', address: '707 Aspen St', specialization: 'Biology' },
    ];

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    const offset = currentPage * itemsPerPage;
    const currentItems = data.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(data.length / itemsPerPage);

    const handleSelectTeacher = (teacher) => {
        // Example teacher data
        const teacherData = {
          name: teacher.name,
          subject: teacher.subject,
          experience: teacher.experience,
        };
    
        // Call the callback function to pass data to App.js
        onTeacherSelect(teacherData);
    
        // Navigate to the teacher's profile
        navigate(`/Admin/Teachers/${teacher.name}`);
      };
    return (
        <>
            <div className='headerpart'>
                <AdminHeader />
            </div>
            <div className='boxparent'>
                <AdminSidebar collapsesidebar={collapsesidebar} />
                <div className={collapse ? 'parentarea collapsed' : 'parentarea'}>

                    <Row>
                        <Col className='pagetitle'>Teachers</Col>
                    </Row>
                    <div className='profileParent commonbgradius'>
                        <div className='contact marginleftauto maxwidth200' onClick={handleShow}>Add New Teacher</div>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add a New Teacher</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form fields={schoolFormFields} onSubmit={handleSchoolFormSubmit} buttonText="Add" />
                            </Modal.Body>

                        </Modal>
                        <div className='tableparent'>
                            <Table responsive  hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Address</th>
                                        <th>Specialization</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{offset + index + 1}</td>
                                            <td onClick={() => handleSelectTeacher({ name: item.name, subject: 'Math', experience: '11 years' })}>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.contact}</td>
                                            <td>{item.address}</td>
                                            <td>{item.specialization}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {data.length > itemsPerPage && (
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                            )}
                        </div>
                    </div>

                </div>

            </div>

        </>
    );
};

export default AdminTeachers;