// src/components/HomePage.js
import React, { useState } from 'react';
import TeacherHeader from './TeacherHeader';
import TeacherSidebar from './TeacherSidebar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TeacherExamination = () => {
    const [collapse, setcollapse] = useState(false);
    const collapsesidebar = () => {
        setcollapse(!collapse);
    }
    return (
        <>
            <div className='headerpart'>
                <TeacherHeader />
            </div>
            <div className='boxparent'>
                <TeacherSidebar collapsesidebar={collapsesidebar} />
                <div className={collapse ? 'parentarea collapsed' : 'parentarea'}>
                    
                    <Row>
                        <Col>Teacher Examinations</Col>
                    </Row>

                </div>

            </div>
        </>
    );
};

export default TeacherExamination;