// src/components/HomePage.js
import React from 'react';
import Form from './Form';


import './global.css'
const ChangePassword = () => {
    const changePasswordFields = [
        {
            label: 'Old Password',
            name: 'oldPassword',
            validate: (value) => value === '',
            errorMessage: 'Old Password is required',
            type: 'password',
        },
        {
            label: 'New Password',
            name: 'newPassword',
            validate: (value) => value === '',
            errorMessage: 'New Password is required',
            type: 'password',
        },
        {
            label: 'Confirm New Password',
            name: 'confirmPassword',
            validate: (value, form) => value !== form.newPassword && form.newPassword !== '',
            errorMessage: 'Passwords do not match',
            type: 'password',
        },
    ];
    const handlePasswordChange = (formData) => {
        console.log('Password Change Submitted', formData);
        // Implement the API call to change the password here
    };
    return (
        <>
            <div className="homepage-ver-11">
                <header className="frame-parent">
                    <div className="frame-wrapper alignhorizontalcenter">
                        <a href="/" className="group-parent ">
                            <img alt="" className="group-icon1 logoimg" src={require('./images/logo.png')} />
                        </a>
                    </div>

                </header>
                <div className="pagetitle alignhorizontalcenter">
                    Change Password
                </div>
                <div className='changepwdbox'>
                    <Form
                        fields={changePasswordFields}
                        onSubmit={handlePasswordChange}
                        buttonText="Change Password"
                    />
                </div>


            </div>
        </>
    );
};

export default ChangePassword;